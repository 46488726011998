import { Component } from '@angular/core';

interface PageRoute {
  title: string;
  url: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  appPages: PageRoute[] = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Arrow Header Demo',
      url: '/arrow-header-demo',
    },
    {
      title: 'Attachment List Demo',
      url: '/attachment-list-demo',
    },
    {
      title: 'Attachment Preview Demo',
      url: '/attachment-preview-demo',
    },
    {
      title: 'Barcode Scanner Demo',
      url: '/barcode-scanner-demo',
    },
    {
      title: 'Button Demo',
      url: '/button-demo',
    },
    {
      title: 'Button Toggle Demo',
      url: '/button-toggle-demo',
    },
    {
      title: 'Capture Image Preview Demo',
      url: '/capture-image-preview-demo',
    },
    {
      title: 'Card Demo',
      url: '/card-demo',
    },
    {
      title: 'Card Layout Demo',
      url: '/card-layout-demo',
    },
    {
      title: 'Chip Demo',
      url: '/chip-demo',
    },
    {
      title: 'Counter Field Demo',
      url: '/counter-field-demo',
    },
    {
      title: 'Date Select Demo',
      url: '/date-select-demo',
    },
    {
      title: 'Date Range Picker Demo',
      url: '/date-range-picker-demo',
    },
    {
      title: 'Date Time Picker Demo',
      url: '/date-time-picker-demo',
    },
    {
      title: 'Dialogue Demo',
      url: '/dialogue-demo',
    },
    {
      title: 'Doughnut Chart Demo',
      url: '/doughnut-chart-demo',
    },
    {
      title: 'Expandable Row Demo',
      url: '/expandable-row-demo',
    },
    {
      title: 'Footer Demo',
      url: '/footer-demo',
    },
    {
      title: 'Home Widget Demo',
      url: '/home-widget-demo',
    },
    {
      title: 'Image Editor',
      url: '/image-editor-demo',
    },
    {
      title: 'Image Gallery',
      url: '/image-gallery-demo',
    },
    {
      title: 'Image Viewer',
      url: '/image-viewer-demo',
    },
    {
      title: 'Input Field Demo',
      url: '/input-field-demo',
    },
    {
      title: 'Line Graph Demo',
      url: '/line-graph-demo',
    },
    {
      title: 'Modal Sheet Demo',
      url: '/modal-sheet-demo',
    },
    {
      title: 'Modal Sheet Select Field Demo',
      url: '/modal-sheet-select-field-demo',
    },
    {
      title: 'Modal Top Sheet Demo',
      url: '/modal-top-sheet-demo',
    },
    {
      title: 'Popover Demo',
      url: '/popover-demo',
    },
    {
      title: 'Progress Bar Demo',
      url: '/progress-bar-demo',
    },
    {
      title: 'Progress Timeline Demo',
      url: '/progress-timeline-demo',
    },
    {
      title: 'Qrcode Scanner Demo',
      url: '/qrcode-scanner-demo',
    },
    {
      title: 'Route List Demo',
      url: '/route-list-demo',
    },
    {
      title: 'Search Bar Demo',
      url: '/search-bar-demo',
    },
    {
      title: 'Select Field Demo',
      url: '/select-field-demo',
    },
    {
      title: 'Select Popover Demo',
      url: '/select-popover-demo',
    },
    {
      title: 'Signature Pad Demo',
      url: '/signature-pad-demo',
    },
    {
      title: 'Slides Demo',
      url: 'slides-demo',
    },
    {
      title: 'Snackbar Demo',
      url: '/snackbar-demo',
    },
    {
      title: 'Star Rating Demo',
      url: 'star-rating-demo',
    },
    {
      title: 'Stepper Demo',
      url: '/stepper-demo',
    },
    {
      title: 'Tab Demo',
      url: '/tab-demo',
    },
    {
      title: 'Tag Demo',
      url: '/tag-demo',
    },
    {
      title: 'Theme Demo',
      url: 'theme-demo',
    },
    {
      title: 'Timeline Demo',
      url: '/timeline-demo',
    },
    {
      title: 'User Item Demo',
      url: '/user-item-demo',
    },
    {
      title: 'Weekly Calendar Demo',
      url: '/weekly-calendar-demo',
    },
  ];
}
