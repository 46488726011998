import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'theme-demo',
    loadChildren: () =>
      import('./theme-demo/theme-demo.module').then(
        (m) => m.ThemeDemoPageModule
      ),
  },
  {
    path: 'snackbar-demo',
    loadChildren: () =>
      import('./snackbar-demo/snackbar-demo.module').then(
        (m) => m.SnackbarDemoPageModule
      ),
  },
  {
    path: 'dialogue-demo',
    loadChildren: () =>
      import('./dialogue-demo/dialogue-demo.module').then(
        (m) => m.DialogueDemoPageModule
      ),
  },
  {
    path: 'home-widget-demo',
    loadChildren: () =>
      import('./home-widget-demo/home-widget-demo.module').then(
        (m) => m.HomeWidgetDemoPageModule
      ),
  },
  {
    path: 'card-demo',
    loadChildren: () =>
      import('./card-demo/card-demo.module').then((m) => m.CardDemoPageModule),
  },
  {
    path: 'tag-demo',
    loadChildren: () =>
      import('./tag-demo/tag-demo.module').then((m) => m.TagDemoPageModule),
  },
  {
    path: 'search-bar-demo',
    loadChildren: () =>
      import('./search-bar-demo/search-bar-demo.module').then(
        (m) => m.SearchBarDemoPageModule
      ),
  },
  {
    path: 'tab-demo',
    loadChildren: () =>
      import('./tab-demo/tab-demo.module').then((m) => m.TabDemoPageModule),
  },
  {
    path: 'button-toggle-demo',
    loadChildren: () =>
      import('./button-toggle-demo/button-toggle-demo.module').then(
        (m) => m.ButtonToggleDemoPageModule
      ),
  },
  {
    path: 'date-time-picker-demo',
    loadChildren: () =>
      import('./date-time-picker-demo/date-time-picker-demo.module').then(
        (m) => m.DateTimePickerDemoPageModule
      ),
  },
  {
    path: 'modal-sheet-demo',
    loadChildren: () =>
      import('./modal-sheet-demo/modal-sheet-demo.module').then(
        (m) => m.ModalSheetDemoPageModule
      ),
  },
  {
    path: 'popover-demo',
    loadChildren: () =>
      import('./popover-demo/popover-demo.module').then(
        (m) => m.PopoverDemoPageModule
      ),
  },
  {
    path: 'select-popover-demo',
    loadChildren: () =>
      import('./select-popover-demo/select-popover-demo.module').then(
        (m) => m.SelectPopoverDemoPageModule
      ),
  },
  {
    path: 'date-select-demo',
    loadChildren: () =>
      import('./date-select-demo/date-select-demo.module').then(
        (m) => m.DateSelectDemoPageModule
      ),
  },
  {
    path: 'attachment-preview-demo',
    loadChildren: () =>
      import('./attachment-preview-demo/attachment-preview-demo.module').then(
        (m) => m.AttachmentPreviewDemoPageModule
      ),
  },
  {
    path: 'attachment-list-demo',
    loadChildren: () =>
      import('./attachment-list-demo/attachment-list-demo.module').then(
        (m) => m.AttachmentListDemoPageModule
      ),
  },
  {
    path: 'chip-demo',
    loadChildren: () =>
      import('./chip-demo/chip-demo.module').then((m) => m.ChipDemoPageModule),
  },
  {
    path: 'stepper-demo',
    loadChildren: () =>
      import('./stepper-demo/stepper-demo.module').then(
        (m) => m.StepperDemoPageModule
      ),
  },
  {
    path: 'slides-demo',
    loadChildren: () =>
      import('./slides-demo/slides-demo.module').then(
        (m) => m.SlidesDemoPageModule
      ),
  },
  {
    path: 'star-rating-demo',
    loadChildren: () =>
      import('./star-rating-demo/star-rating-demo.module').then(
        (m) => m.StarRatingDemoPageModule
      ),
  },
  {
    path: 'signature-pad-demo',
    loadChildren: () =>
      import('./signature-pad-demo/signature-pad-demo.module').then(
        (m) => m.SignaturePadDemoPageModule
      ),
  },
  {
    path: 'timeline-demo',
    loadChildren: () =>
      import('./timeline-demo/timeline-demo.module').then(
        (m) => m.TimelineDemoPageModule
      ),
  },
  {
    path: 'user-item-demo',
    loadChildren: () =>
      import('./user-item-demo/user-item-demo.module').then(
        (m) => m.UserItemDemoPageModule
      ),
  },
  {
    path: 'image-editor-demo',
    loadChildren: () =>
      import('./image-editor-demo/image-editor-demo.module').then(
        (m) => m.ImageEditorDemoPageModule
      ),
  },
  {
    path: 'input-field-demo',
    loadChildren: () =>
      import('./input-field-demo/input-field-demo.module').then(
        (m) => m.InputFieldDemoPageModule
      ),
  },
  {
    path: 'select-field-demo',
    loadChildren: () =>
      import('./select-field-demo/select-field-demo.module').then(
        (m) => m.SelectFieldDemoPageModule
      ),
  },
  {
    path: 'modal-sheet-select-field-demo',
    loadChildren: () =>
      import(
        './modal-sheet-select-field-demo/modal-sheet-select-field-demo.module'
      ).then((m) => m.ModalSheetSelectFieldDemoPageModule),
  },
  {
    path: 'weekly-calendar-demo',
    loadChildren: () =>
      import('./weekly-calendar-demo/weekly-calendar-demo.module').then(
        (m) => m.WeeklyCalendarDemoPageModule
      ),
  },
  {
    path: 'arrow-header-demo',
    loadChildren: () =>
      import('./arrow-header-demo/arrow-header-demo.module').then(
        (m) => m.ArrowHeaderDemoPageModule
      ),
  },
  {
    path: 'line-graph-demo',
    loadChildren: () =>
      import('./line-graph-demo/line-graph-demo.module').then(
        (m) => m.LineGraphDemoPageModule
      ),
  },
  {
    path: 'doughnut-chart-demo',
    loadChildren: () =>
      import('./doughnut-chart-demo/doughnut-chart-demo.module').then(
        (m) => m.DoughnutChartDemoPageModule
      ),
  },
  {
    path: 'modal-top-sheet-demo',
    loadChildren: () =>
      import('./modal-top-sheet-demo/modal-top-sheet-demo.module').then(
        (m) => m.ModalTopSheetDemoPageModule
      ),
  },
  {
    path: 'button-demo',
    loadChildren: () =>
      import('./button-demo/button-demo.module').then(
        (m) => m.ButtonDemoPageModule
      ),
  },
  {
    path: 'image-gallery-demo',
    loadChildren: () =>
      import('./image-gallery-demo/image-gallery-demo.module').then(
        (m) => m.ImageGalleryDemoPageModule
      ),
  },
  {
    path: 'progress-bar-demo',
    loadChildren: () =>
      import('./progress-bar-demo/progress-bar-demo.module').then(
        (m) => m.ProgressBarDemoPageModule
      ),
  },
  {
    path: 'counter-field-demo',
    loadChildren: () =>
      import('./counter-field-demo/counter-field-demo.module').then(
        (m) => m.CounterFieldDemoPageModule
      ),
  },
  {
    path: 'barcode-scanner-demo',
    loadChildren: () =>
      import('./barcode-scanner-demo/barcode-scanner-demo.module').then(
        (m) => m.BarcodeScannerDemoPageModule
      ),
  },
  {
    path: 'expandable-row-demo',
    loadChildren: () =>
      import('./expandable-row-demo/expandable-row-demo.module').then(
        (m) => m.ExpandableRowDemoPageModule
      ),
  },
  {
    path: 'image-viewer-demo',
    loadChildren: () =>
      import('./image-viewer-demo/image-viewer-demo.module').then(
        (m) => m.ImageViewerDemoPageModule
      ),
  },
  {
    path: 'qrcode-scanner-demo',
    loadChildren: () =>
      import('./qrcode-scanner-demo/qrcode-scanner-demo.module').then(
        (m) => m.QrcodeScannerDemoPageModule
      ),
  },
  {
    path: 'capture-image-preview-demo',
    loadChildren: () =>
      import(
        './capture-image-preview-demo/capture-image-preview-demo.module'
      ).then((m) => m.CaptureImagePreviewDemoPageModule),
  },
  {
    path: 'card-layout-demo',
    loadChildren: () =>
      import('./card-layout-demo/card-layout-demo.module').then(
        (m) => m.CardLayoutDemoPageModule
      ),
  },
  {
    path: 'footer-demo',
    loadChildren: () =>
      import('./footer-demo/footer-demo.module').then(
        (m) => m.FooterDemoPageModule
      ),
  },
  {
    path: 'progress-timeline-demo',
    loadChildren: () =>
      import('./progress-timeline-demo/progress-timeline-demo.module').then(
        (m) => m.ProgressTimelineDemoPageModule
      ),
  },
  {
    path: 'route-list-demo',
    loadChildren: () =>
      import('./route-list-demo/route-list-demo.module').then(
        (m) => m.RouteListDemoPageModule
      ),
  },
  {
    path: 'date-range-picker-demo',
    loadChildren: () =>
      import('./date-range-picker-demo/date-range-picker-demo.module').then(
        (m) => m.DateRangePickerDemoPageModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
